'use client';

import { FormattedMessage } from 'react-intl';
import { BaseStyleCardConfig, BaseStyleId, DynamicStyleCardConfig, DynamicStyleId, StaticStyleCardConfig, StaticStyleId } from '../types/Styles';

export const baseStyleCardConfigs: Record<BaseStyleId, BaseStyleCardConfig> = {
  adminBasemap: {
    displayName: 'Custom basemap',
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-layer-adminBasemap@2x.png',
    key: 'adminBasemap',
    requiresAdmin: true,
    requiresPLP: true,
    requiresAdminStyleSourceUrl: true
  },
  alltrailsOutdoorsV2: {
    displayName: 'AllTrails',
    description: <FormattedMessage defaultMessage="Extensive trail & terrain map" />,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v3/map-layer-alltrailsOutdoorsV2@2x.png',
    key: 'alltrailsOutdoorsV2',
    supports3d: true
  },
  alltrailsSatellite: {
    displayName: <FormattedMessage defaultMessage="Satellite" />,
    description: <FormattedMessage defaultMessage="Detailed satellite imagery" />,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-layer-alltrailsSatellite@2x.png',
    key: 'alltrailsSatellite',
    supports3d: true
  },
  alltrailsSatelliteMinimal: {
    displayName: 'Satellite minimal',
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-layer-alltrailsSatellite@2x.png',
    key: 'alltrailsSatelliteMinimal',
    supports3d: true
  },
  googleSatellite: {
    displayName: 'Google satellite',
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-layer-googleSatellite@2x.png',
    key: 'googleSatellite',
    requiresAdmin: true
  },
  opencycle: {
    displayName: 'OCM',
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-layer-opencycle@2x.png',
    key: 'opencycle',
    requiresAdmin: true
  },
  openstreet: {
    displayName: 'OSM',
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-layer-openstreet@2x.png',
    key: 'openstreet',
    requiresAdmin: true
  },
  roadmap: {
    displayName: <FormattedMessage defaultMessage="Road" />,
    description: <FormattedMessage defaultMessage="Streets & buildings" />,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-layer-roadmap@2x.png',
    key: 'roadmap'
  },
  topo: {
    displayName: <FormattedMessage defaultMessage="USGS topo (US only)" />,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-layer-topo@2x.png',
    key: 'topo',
    requiresAdmin: true
  },
  worldparks: {
    displayName: <FormattedMessage defaultMessage="Terrain" />,
    description: <FormattedMessage defaultMessage="Topo map with park boundaries" />,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-layer-worldparks@2x.png',
    key: 'worldparks'
  },
  worldtopo: {
    displayName: <FormattedMessage defaultMessage="Terrain (legacy)" />,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-layer-worldtopo@2x.png',
    key: 'worldtopo',
    requiresAdmin: true
  }
};

export const staticStyleCardConfigs: Record<StaticStyleId, StaticStyleCardConfig> = {
  airQuality: {
    displayName: <FormattedMessage defaultMessage="Air quality" />,
    description: <FormattedMessage defaultMessage="Real-time air quality index" />,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-overlay-air-quality@2x.png',
    key: 'airQuality',
    requiresPlus: true
  },
  adminOverlay: {
    displayName: 'Custom overlay',
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-overlay-admin-overlay@2x.png',
    key: 'adminOverlay',
    requiresAdmin: true,
    requiresPLP: true,
    requiresAdminStyleSourceUrl: true
  },
  lightPollution: {
    displayName: <FormattedMessage defaultMessage="Light pollution" />,
    description: <FormattedMessage defaultMessage="Nighttime light sources" />,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-overlay-light-pollution@2x.png',
    key: 'lightPollution',
    requiresPlus: true
  },
  pollen: {
    displayName: <FormattedMessage defaultMessage="Pollen" />,
    description: <FormattedMessage defaultMessage="Daily pollen forecast" />,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-overlay-pollen@2x.png',
    key: 'pollen',
    requiresPlus: true
  },
  waymarkedCycling: {
    displayName: <FormattedMessage defaultMessage="Waymarked cycling" />,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-overlay-waymarked-cycling@2x.png',
    key: 'waymarkedCycling',
    requires2d: true,
    requiresGPSies: true
  },
  waymarkedHiking: {
    displayName: <FormattedMessage defaultMessage="Waymarked hiking" />,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-overlay-waymarked-hiking@2x.png',
    key: 'waymarkedHiking',
    requires2d: true,
    requiresGPSies: true
  },
  waymarkedMTB: {
    displayName: <FormattedMessage defaultMessage="Waymarked MTB" />,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-overlay-waymarked-mtb@2x.png',
    key: 'waymarkedMTB',
    requires2d: true,
    requiresGPSies: true
  },
  weather: {
    displayName: <FormattedMessage defaultMessage="Weather" />,
    description: <FormattedMessage defaultMessage="Current precipitation intensity" />,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v3/map-overlay-weather@2x.png',
    key: 'weather',
    requiresPlus: true
  }
};

export const dynamicStyleCardConfigs: Record<DynamicStyleId, DynamicStyleCardConfig> = {
  after: {
    displayName: 'Last 60 days',
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-overlay-after@2x.png',
    key: 'after',
    // PLAT-2212 trails:manage
    requiresAdmin: true,
    requiresPLP: true
  },
  clusterTrails: {
    displayName: 'Cluster trails',
    hasHeatmap: true,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-overlay-cluster-trails@2x.png',
    key: 'clusterTrails',
    requires2d: true,
    requiresAdmin: true
  },
  contribs: {
    displayName: 'Contrib VRs',
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-overlay-contribs@2x.png',
    key: 'contribs',
    requires2d: true,
    requiresAdmin: true
  },
  distanceMarkers: {
    displayName: <FormattedMessage defaultMessage="Distance markers" />,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v3/map-overlay-distance-markers@2x.png',
    key: 'distanceMarkers'
  },
  geoBoundaries: {
    displayName: 'Polygons',
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v3/map-overlay-geo-boundaries@2x.png',
    key: 'geoBoundaries',
    requiresAdmin: true
  },
  heatmap: {
    displayName: <FormattedMessage defaultMessage="Heatmap" />,
    description: <FormattedMessage defaultMessage="Recent trail activity" />,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v3/map-overlay-heatmap@2x.png',
    hideFromPLP: true,
    key: 'heatmap',
    requiresPlus: true
  },
  limit: {
    displayName: 'Most recent 50',
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-overlay-limit@2x.png',
    key: 'limit',
    requires2d: true,
    // PLAT-2212 trails:manage
    requiresAdmin: true
  },
  mapsAll: {
    displayName: 'Maps all',
    hasHeatmap: true,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v3/map-overlay-maps-all@2x.png',
    key: 'mapsAll',
    requires2d: true,
    requiresAdmin: true
  },
  nearbyTrails: {
    displayName: <FormattedMessage defaultMessage="Nearby trails" />,
    hasHeatmap: true,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v3/map-overlay-nearby-trails@2x.png',
    key: 'nearbyTrails'
  },
  pendingTrails: {
    displayName: 'Pending trails',
    hasHeatmap: true,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-overlay-pending-trails@2x.png',
    key: 'pendingTrails',
    requiresAdmin: true
  },
  photos: {
    displayName: <FormattedMessage defaultMessage="Photos along the trail" />,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v3/map-overlay-photos@2x.png',
    key: 'photos'
  },
  recordingsAll: {
    displayName: 'Recordings all',
    hasHeatmap: true,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-overlay-recordings-all@2x.png',
    key: 'recordingsAll',
    requiresAdmin: true,
    requiresPLP: true
  },
  recordingsPop: {
    displayName: 'Recordings popular',
    hasHeatmap: true,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v2/map-overlay-recordings-pop@2x.png',
    key: 'recordingsPop',
    requiresAdmin: true
  },
  waypoints: {
    displayName: <FormattedMessage defaultMessage="Waypoints" />,
    imgSrc: 'https://cdn-assets.alltrails.com/assets/map-layers/v3/map-overlay-waypoints@2x.png',
    key: 'waypoints'
  }
};
